#urban-radar {
  width: 100%;
  padding: 40px;
  height: auto;
  max-width: 300px;
  margin-top: 50px;
  background-color: white;
  margin-left: 0px;
}

#solutions {
  width: 100%;
  height: auto;
  max-width: 200px;
  margin-top: 50px;
  min-height: 150px;
}

#depaul {
  width: 100%;
  height: auto;
  max-width: 200px;
  min-width: 150px;
  margin-top: 50px;
}

.partners-image {
  margin-right: 25px;;
  margin-left: 25px;
  min-width: 200px;
}


#partners-margin {
  margin-left: 40px;
}

.partners-text {
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
  width: 50%;
}

.wrapper {
  position: relative;
  text-align: center;
  width: 100%;
}

.partners-images {
  margin-bottom: 20px;
}

@media screen and (min-width: 1440px) {
  .partners-text {
    width: 60%;
  }
}


@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .partners-text {
    width: 100% ;
  }
  #urban-text {
    margin-left: 0;
    margin-top: 10px;
  }
  #solutions-text {
    margin-left: 0px;
  }
  #depaul-text {
    margin-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .partners-text {
    width: 100% ;
  }
  #urban-text {
    margin-left: 0;
    margin-top: 10px;
  }
  #solutions-text {
    margin-left: 20px;
  }
  #depaul-text {
    margin-left: 20px;
  }
}

@media screen and (min-width: 736px) and (max-width: 767px) {
  .partners-text {
    width: 60% ;
  }
}

@media screen and (width: 667px) {
  .partners-text {
    width: 40%;
    margin-left: 185px;
  }
  #urban-radar {
    margin-left: 25px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 640px) {
  #urban-radar {
    margin-left: 20px;
  }
  .partners-text {
    width: 60%;
  }
  #partners-margin {
    margin-left: 25px;
  }
}


@media screen and (min-width: 2150px) {
  .partners-text {
    width: 40%;
  }
}


@media screen and (min-width: 361px) and (max-width: 400px) {
  #urban-radar {
    margin-left: 5px;
  }
  .partners-text {
    width: 80%;
  }
  #partners-margin {
    margin-left: 25px;
  }
}


@media screen and (min-width: 320px) and (max-width: 360px) {
  .partners-text {
    width: 80%;
  }
  #urban-radar {
    margin-left: 0px;
  }
  #partners-margin {
    margin-left: 25px;
  }
}
