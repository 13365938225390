.toggle-button {
  display: flex;
  flex-direction: column;
  height: 30px;
  width: 40px;
  padding-right: 60px;
  margin-top: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  visibility: hidden;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button-line {
  width: 35px;
  height: 5px;
  background-color: black;
}

@media screen and (max-width: 1280px) {
  .toggle-button {
    margin-top: -30px;
    padding-right: 80px;
    visibility: visible;
  }
  .nav {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .toggle-button {
    margin-top: 20px !important;
    padding-right: 70px;
  }
}

@media screen and (max-width: 700px) {
  .toggle-button {
    margin-top: 10px;
    padding-right: 70px;
  }
}

@media screen and (max-width: 600px) {
  .toggle-button {
    margin-top: 10px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 400px) {
  .toggle-button {
    margin-top: -30px;
    padding-right: 80px !important;
  }
}

@media screen and (max-width: 360px) {
  .toggle-button {
    margin-right: -5px !important;
    margin-top: 10px;
  }
}

@media screen and (max-width: 320px) {
  .toggle-button {
    margin-right: -15px !important;
    margin-top: -10px;
  }
}
