#post-covid {
  margin-bottom: -8px;
}

#blog-heading {
  margin-left: 40px;
  margin-bottom: 50px;
}

.blog-img {
  width: 100vw;
  height: auto;
  max-width: 550px;
  min-width: 300px;
  margin-left: -80px;
}

.blog-title {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 40px;
  margin-left: -80px;
}

.col-md-5 {
  max-width: 500px;
}

#loading {
  margin-left: 50px;
}

@media only screen and (max-width: 768px) {
  .blog-title {
    margin-left: 140px !important;
    margin-right: 25px !important;
  }
  .blog-img {
    margin-left: 25px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .blog-title {
    margin-right: -100px;
    margin-left: 230px;
  }
  .blog-img {
    margin-left: 120px;
  }
}


@media only screen and (max-width: 812px) {
  .blog-title {
    margin-right: -100px;
    margin-left: 75px;
  }
  .blog-img {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 736px) {
  .blog-title {
    padding-left: 0px !important;
    margin-right: -100px !important;
  }
  .blog-img {
    margin-left: 70px !important;
  }
}

@media screen and (max-width: 700px) {
  .blog-title {
    margin-right: -100px !important;
    margin-left: 100px !important;
  }
  .blog-img {
    margin-left:  50px !important;
  }
}

@media screen and (width: 667px) {
  .blog-title {
    margin-left: 115px !important;
    margin-right: -35px !important;
  }
  .blog-img {
    margin-left: 35px !important;
  }
}

@media screen and (max-width: 600px) {
  #blog-heading {
    margin-left: 0px;
  }
  .blog-title {
    margin-left: 50px !important;
    margin-right: 35px !important;
  }
  .blog-img {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 640px) {
  .blog-title {
    margin-left: 140px !important;
    margin-right: 35px !important;
  }
  .blog-img {
    margin-left: 20px !important;
  }
}

@media only screen and (max-width: 568px) {
  .blog-title {
    margin-left: 120px !important;
    margin-right: 60px !important;
  }
  .blog-img {
    margin-left: -20px !important;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 414px) {
  #blog-heading {
    margin-left: 25px;
  }

  .blog-title {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .blog-img {
    margin-left: -30px !important;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 375px) {
  #blog-heading {
    margin-left: 20px;
  }
  .blog-title {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .blog-img {
    margin-left: -28px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 360px) {
  .blog-title {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .blog-img {
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 320px) {
  .blog-title {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .blog-img {
    margin-left: -28px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}
