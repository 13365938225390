#blog-footer {
  border-top: 3px solid black;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
}


@media only screen and (min-width: 2560px) {
  #blog-footer {
    bottom: -200px;
  }
}
