
#img-people {
  width: 100%;
  max-height: 100vh;
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 25px;
  image-rendering: auto;
  padding: 0;
}

#img-container {
  position: relative;
}

#image-text {
  position: absolute;
  word-wrap: break-word;
  text-align: center;
  color: white;
  font-weight: bold;
  left: 0%;
  top: 40%;
  font-size: 5.2vw;
  z-index: 1;
  font-family: helvetica;
  width: 100%;
}

.flex-space-around {
  display: flex;
  justify-content: center;
}

#tone-heading {
  font-weight: bold !important;
  margin-top: 20px;
  margin-left: 50px;
}

.helping-sentence {
  font-size: 18px;
  word-wrap: break-word;
  margin-left: 100px;
  margin-right: 0px;
}

.contact {
  word-wrap: break-word;
  font-size: 18px;
}

#brandon-contact {
  margin-bottom: 50px;
}

.what-we-do {
  margin-left: 50px;
  font-size: 18px;
}

.what-we-do-list-item {
  margin-left: 35px !important;
  margin-right: 35px;
  font-size: 18px;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 375px) {
  /* #img-people {
    margin-left: 20px;
  } */
  .new-tech {
    margin-left: 60px;
  }

}

@media screen and (max-width: 401px) {
  .what-we-do-list-item {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  #img-people {
    padding-right: 40px;
  }
}

@media screen and (min-width: 401px) {
  .what-we-do-list-item {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 360px) {
  #img-people {
    margin-left: 0px;
  }

  .new-tech {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 600px) {
  #twelve-tone-heading {
    text-align: center;
  }
}
