.heading-margin {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}

.paragraph-styles {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 15px;
  font-size: 18px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 400px) {
  .paragraph-styles{
    margin-left: 40px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 700px) {
  .paragraph-styles{
    margin-left: 40px;
    margin-right: 40px;
  }
}
