
.name {
  text-align: center;
  overflow-wrap: break-word;
  margin-top: 10px;
  margin-right: -40px;
}

.col-md-3 {
  max-width: 320px;
  margin-left: 100px;
}

.role {
  text-align: center;
  overflow-wrap: break-word;
  margin-right: -30px;
}

.in {
  margin-bottom: 30px;
  margin-right: -40px;
}

.staff-image {
  width: 100%;
  height: auto;
  max-width: 190px;
  min-width: 150px;
  border-radius: 50%;
  image-rendering: auto;
  margin-left: 70px;
}

#who {
  margin-bottom: 50px;
  margin-left: 50px;
}


@media screen and (max-width: 1024px) {
  .staff-image {
    margin-left: 120px !important;
    margin-right: 20px !important;
  }
  .name {
    margin-left: 110px;
  }
  .role {
    margin-left: 125px;
  }
  .in {
    margin-left: 115px;
  }
}

@media screen and (max-width: 812px) {
  #who {
    margin-left: 50px;
  }

  .staff-image {
    margin-left: 30px !important;
  }

  .name {
    margin-left: 10px;
  }
  .role {
    margin-left: 30px;
  }
  .in {
    margin-left: 10px;
  }
}


@media screen and (max-width: 768px) {
  .staff-image {
    margin-left: 15px !important;
  }
  .name {
    margin-left: 20px;
  }
  .role {
    margin-left: 25px;
  }
  .in {
    margin-left: 20px;
  }
}

@media screen and (max-width: 736px) {
  #who {
    margin-left: 5px;
  }

  .staff-image {
    margin-left: 150px !important;
  }
  .name {
    margin-left: 150px;
  }
  .role {
    margin-left: 160px;
  }
  .in {
    margin-left: 150px;
  }
}

@media screen and (max-width: 568px) {
  .staff-image {
    margin-left: 100px !important;
  }
}

@media screen and (max-width: 667px) {
  .staff-image {
    margin-left: 70px !important;
  }
  .name {
    margin-left: -10px;
  }
  .role {
    margin-left: 20px;
  }
  .in {
    margin-left: 0px;
  }

}

@media screen and (max-width: 640px) {
  .staff-image {
    margin-left: 110px !important;
  }
  .name {
    margin-left: 100px;
  }
  .role {
    margin-left: 100px;
  }
  .in {
    margin-left: 100px;
  }
}

@media screen and (max-width: 500px) {
  .staff-image {
    margin-left: 80px;
  }
}


@media screen and (max-width: 414px) {
  #who {
    margin-left: 35px;
  }

  .staff-image {
    margin-left: -10px !important;
  }

  .name {
    margin-left: -150px;
  }

  .role {
    margin-left: -120px;
  }

  .in {
    margin-left: -140px;
  }
}

@media screen and (max-width: 375px) {
  #who {
    margin-left: 30px;
  }

  .staff-image {
    margin-left: -25px !important;
  }

  .name {
    margin-left: -140px;
  }

  .role {
    margin-left: -120px;
  }
  .in {
    margin-left: -140px;
  }
}

@media screen and (max-width: 360px) {
  #who {
    margin-left: 30px;
  }

  .staff-image {
    margin-left: 15px;
  }

  .in {
    margin-left: -130px;
  }
}

@media screen and (max-width: 320px) {
  .staff-image {
    margin-left: -50px !important;
  }
  .name {
    font-size: 20px;

  }
}
