#heading-style {
  text-decoration: underline;
  font-weight: bold;
}

#whitepaper-text-style {
  font-weight: bold;
  margin-top: 25px;
}

#publish-margin {
  margin-top: -40px;
}

#our-work-margin {
  margin-left: 35px;
}

.pdf-img {
  width: 100%;
  height: auto%;
  max-height: 400px;
  min-width: 350px;
  max-width: 300px;
  margin-top: 50px;
  image-rendering: auto;
  margin-left: -70px;
  padding-right: 20px;
  margin-bottom: 20px;
}

#trb {
  height: 400px;
}


.pdf-img:hover {
  cursor: pointer;
}

#bal {
  margin-top: 30px;
}

@media only screen and (min-width: 2560px) {
}
/* @media only screen and (min-width: 1500px) {
  .pdf-img {
    max-height: 800px !important;
  }
} */

@media only screen and (max-width: 1024px) {
  .pdf-img {
   margin-left: 10px;
  }
  #bal {
   margin-top: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .pdf-img {
   padding-left: 30px;
   padding-right: 30px;
   padding-top: 30px;
   padding-bottom: 10px;
   margin-left: 5px;
   margin-top: 5px;
  }
}

@media only screen and (max-width: 812px) {
  .pdf-img {
   /* padding-left: 50px;
   padding-right: 50px; */
   padding-top: 10px;
   /* padding-bottom: 20px; */
   margin-left: 100px;
   margin-top: 25px;
  }
}


@media only screen and (max-width: 768px) {
  .pdf-img {
   padding-top: 10px;
   margin-left: 85px;
   margin-top: 25px;
  }

  #presented-at {
   margin-top: -5px;
  }
}

@media only screen and (max-width: 500px) {
  .pdf-img {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 450px) {
  .pdf-img {
    padding-left: 50px;
    padding-right: 50px;
    margin-left: 0px !important;
    margin-bottom: 20px;
  }

  #bal {
    margin-top: 25px;
  }

  #urban {
    margin-top: 25px;
  }

  #dockless {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 736px) {
  .pdf-img {
    margin-left: 70px !important;
  }
}

@media only screen and (max-width: 667px) {
  .pdf-img {
    margin-left: 30px !important;
  }
}

@media only screen and (max-width: 640px) {
  .pdf-img {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 568px) {
  .pdf-img {
    margin-left: -25px !important;
  }
}

@media only screen and (max-width: 414px) {
  .pdf-img {
    margin-left: -100px !important;
  }

  #our-work-margin {
    margin-left: 20px !important;
  }
}

@media only screen and (max-width: 375px) {
  #our-work-margin {
    margin-left: 15px;
  }

  .pdf-img {
    margin-left: -115px !important;
  }
}

@media only screen and (max-width: 360px) {
.pdf-img {
    margin-left: -125px !important;
  }
  #our-work-margin {
    margin-left: 15px !important;
  }
  #dockless {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 320px) {
  .pdf-img {
    margin-left: -145px !important;
  }
}
