.side-drawer {
  height: 100%;
  width: 225px;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.81);
  z-index: 3000;
  top: 0;
  right: 0;
  overflow-y: scroll;
}

.side-drawer-ul {
  display: flex;
  flex-direction: column;
}

.side-drawer-link {
  font-size: 20px;
  margin-top: 55px;
  padding-right: 50px;
  list-style-type: none;
}


.side-drawer-link a {
  color: #F7C2B9;
  cursor: pointer;
  text-decoration: none;
}

li a:hover {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 600px) {
  li {
    margin-top: 40px;
  }
}

@media screen and (max-width: 360px) {
  li {
    margin-top: 45px !important;
  }
}
