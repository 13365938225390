body, html{
  box-sizing: border-box;
  background-color: #F7C2B9;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  font-family: 'Lato';
  overflow-x:hidden;
  font-size: 16px;
}
