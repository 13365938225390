.flex-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #F7C2B9;
  width: 100vw;
  min-width: 100vw;
  padding-bottom: 5px;
}

#nav-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

.nav-li {
  margin-right: 75px;
  color: black;
  margin-bottom: 18px;
}

.nav-li:hover {
  color: white;
  text-decoration: none;
}


.header-navigation-items a {
  color: black;
  text-decoration: none;
}

.header-navigation-items a:active {
  color: white;
}

.nav-li-active {
  color: white;
}

#flex-title {
  max-width: 150px;
}

#flex-word {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 40px;
  padding-top: 20px;
  position: absolute;
  left: 105px !important;
  top: -32px !important;
}

.twelve h4{
  margin-bottom: 10px;
}

#header-logo {
  width: 15%;
  height: 75%;
  min-width: 100px;
  border: 3px solid black;
  margin-top: 25px;
  padding-left: 12px;
  padding-bottom: 12px;
  background-color: #F7C2B9;
  position: relative;
}

@media screen and (max-width: 700px) {
  .flex-header {
    padding-bottom: 15px;
  }
  #img-people {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

@media screen and (max-width: 600px) {
  .flex-header {
    padding-bottom: 15px;
  }
  #flex-word {
    left: 140px !important;
    top: 35px !important;
    margin-top: 20px;
  }
  .nav {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  #flex-word {
    left: 300px !important;
    top: -5px;
    margin-top: 20px;
  }
  #header-logo {
    min-width: 60px;
  }

  .twelve {
    margin-left: -40px !important;
    margin-top: -3px;
    font-size: .9em;

  }

  .nav {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  #flex-word {
    left: 105px !important;
    top: -10px !important;
    margin-top: 20px;
  }
  .nav {
    display: none;
  }
}



@media only screen and (max-width: 1066px) {
  .nav-li {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 1099px) {
  .nav-li {
    margin-right: 30px;
    margin-top: 18px;
  }
}

@media only screen and (min-width: 1100px) {
  .nav-li {
    margin-top: 35px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  #flex-word {
    left: 190px;
    top: 12px;
  }
  .nav-li {
    margin-right: 50px;
  }

  #blog {
    margin-right: 15px !important;
  }
}


@media only screen and (min-width: 1400px) {
  #flex-word {
    left: 190px;
    top: 15px;
  }
}
