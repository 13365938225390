.press-styles {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20;
  word-wrap: break-word;
  margin-right: 50px;
  line-height: 2rem;
  font-size: 18px;
  color: black;
  text-decoration: underline;
}

#press-image {
  width: 100%;
  max-height: 100vh;
  display: inline-block;
  margin-bottom: 25px;
  image-rendering: auto;
  padding: 0;
}

.date {
  text-decoration: none;
  font-size: 18px;
}

a:hover {
  text-decoration: none;
}

.press-styles:hover {
  color: white;
}

#press-margin {
  margin-left: 40px;
  margin-bottom: 50px;
}

#streets-blog {
  margin-bottom: 190px;
}

@media screen and (max-width: 700px) {
  .press-styles{
    margin-right: 50px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 640px) {
  #streets-blog {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 414px) {
  #press-margin{
    margin-left: 25px !important;
  }
  #streets-blog {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 375px) {
  #press-margin{
    margin-left: 25px !important;
  }
  #streets-blog {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 360px) {
  .press-styles{
    margin-left: 30px !important;
    margin-right: 20px;
  }
  #streets-blog {
    margin-bottom: 50px !important;
  }
}


@media screen and (max-width: 320px) {
  .press-styles{
    margin-right: 0px;
    margin-left: 15px;
  }
}
