#our-story-margin {
  margin-left: 50px;
  margin-bottom: 50px;
}

#margin-our-story {
  padding-bottom: 25px;
}

.flex-center {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#map-image {
  width: 100%;
  max-width: 900px;
  min-width: 260px;
  height: 50%;
  margin-top: 25px;
  image-rendering: auto !important;
  image-rendering: crisp-edges !important;
  image-rendering: smooth !important;
  image-rendering: -webkit-optimize-contrast !important;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 0px;
}

@media only screen and (min-width: 768px) {
  #margin-our-story {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 414px) {
  #our-story-margin {
    margin-left: 35px;
  }
}

@media screen and (max-width: 375px) {
  #our-story-margin {
    margin-left: 30px;
  }
}

@media screen and (max-width: 360px) {
  #our-story-margin {
    margin-left: 30px;
  }
}
